.slider {
  margin: 0 -1.5rem;

  &__item {
    margin: 0 1.5rem;
  }

  .slick-dots {
    position: absolute;
    bottom: -4rem;
  }

  .slick-prev {
    left: -4rem;
  }

  .slick-next {
    right: -4rem;
  }

  @media (max-width: $tablet-width) {
    margin: 0 -1rem;

    &__item {
      margin: 0 1rem;
    }

    .slick-prev {
      left: -1.5rem;
    }

    .slick-next {
      right: -2.5rem;
    }

    .slick-dots {
      position: absolute;
      bottom: -3rem;
    }
  }

  @media (max-width: $mobile-width) {

  }
}