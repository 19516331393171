.page-wrapper {
  width: 85%;
  max-width: 120rem;
  padding: 2.8rem 0 4.2rem;
  margin: 0 auto;
}

@media (max-width: $tablet-width) {

  .page-wrapper {
    padding: 1.5rem 0 2rem;
  }

  .title--mini {
    font-size: 1.5rem;
  }

  .good-page__slider {
    width: 100%;
    max-width: 100%;
    height: 30rem;
  }

  .good-page__description {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
  }

  .good-page__description-item:not(:last-child) {
    margin-bottom: 1rem;
  }

  .good-page__description-title {
    font-size: 1.5rem;
  }

  .good-page__description-value,
  .table__item {
    font-size: 1.2rem;
  }

  .table__item {
    padding: 0.7rem 1rem;
  }

  .good-page__stores-item {
    width: calc(20% - 2rem*4/5);
    height: auto;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }

  .good-page__stores-item:nth-child(6n) {
    margin-right: 2rem;
  }

  .good-page__stores-item:nth-child(5n) {
    margin-right: 0;
  }

  .good-page__content {
    margin: 3rem 0;
  }

  .good-page__about-item {
    width: 100%;
  }

  .good-page__about-item:nth-child(even) {
    margin-left: 0;
  }

  .good-page__about-item::before {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    font-size: 1.8rem;
  }

  .good-page__about-item {
    font-size: 1.2rem;
  }

  .recepies-slider__item {
    padding: 2rem 0;
  }

  .slick-slide .product__description {
    height: 5rem;
  }

  .page-footer__insta {
    margin: 0 0 1.5rem 0;
  }
}

@media (max-width: $mobile-width) {

  .good-page__body {
    width: 100%;
  }

  .good-page__image-nav {
    display: none;
  }

  .good-page__slider {
    height: 33rem;
  }

  .title--mini {
    font-size: 1.8rem;
  }

  .good-page__description {
    display: flex;
    flex-direction: column;
  }

  .good-page__description-item:nth-child(4) {
    order: -1;
  }

  .good-page__description-title {
    font-size: 1.2rem;
    margin-bottom: 1.8rem;
  }

  .good-page__description-item:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .table__item {
    font-size: 1.2rem;
    display: block;
  }
  
  .table__value {
    padding-left: 0;
    display: block;
  }

  .good-page__content {
    margin: 0;
    margin-bottom: 4rem;
  }

  .slider-recomendation {
    margin-bottom: 4.5rem;

    .recepies-slider__item {
      padding: 0;
    }
  }

  .slider-recomendation__title {
    max-width: 80%;
    font-size: 1.8rem;
  }

  .recepies-slider__button {
    width: 12rem;
  }
}
