.advantages {

  &__body {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -10rem;
      right: 12rem;
      display: block;
      width: 17.5rem;
      height: 34rem;
      background-image: url('../img/icons/pattern.svg');
    }
  }

  &__descritpion {
    padding: 12rem 0 15rem;
    width: 85%;
    max-width: 120rem;
    margin: 0 auto;
    font-family: $title-font;
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.333;
    color: $base-dark-color;
    text-transform: uppercase;

    span {
      color: $base-color;
    }

    &--mobile {
      display: none;
    }
  }

  &__list {
    display: flex;
    height: 58rem;
  }

  &__item {
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
    background-color: $base-color-background;

    &:nth-child(2) {
      background-color: $base-color-background-light;
    }

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 73.75%;
      transform: translateY(-50%);
      display: block;
      width: 25.5rem;
      height: 33.5rem;
      background-image: url('../img/icons/ampersand.svg');
      opacity: 0.5;
    }
  }

  &__item-link {
    display: block;
    width: 100%;
    height: 100%;

    @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

      &:hover,
      &:focus {

        .advantages__item-hover {
          opacity: 1;
        }
      }
    }
  }

  &__item-title {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__item-description {
    width: 100%;
  }

  &__image {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  &__item-hover {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding: 10rem 9rem;
    padding-bottom: 15.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &__item-description {
    margin-bottom: auto;
    font-size: 1.8rem;
    color: $black;
  }

  @media (max-width: $tablet-width) {

    &__body {
      padding: 0;

      &::before {
        top: -3rem;
        right: 3rem;
        width: 8.75rem;
        height: 17rem;
      }
    }

    &__descritpion {
      padding: 7rem 0;
      font-size: 2rem;
    }

    &__list {
      display: block;
      height: auto;
    }

    &__item {

      &:not(:last-child)::after {
        display: none;
      }
    }

    &__item-link {
      padding: 4rem 2rem;
    }

    &__item-title {
      left: 0;
      transform: none;
    }

    &__image {
      height: 30rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__item-hover {
      position: relative;
      margin-top: 3rem;
      padding: 0;
      background-color: transparent;
      opacity: 1;
    }

    &__item-description {
      font-size: 1.6rem;
    }

    &__item-more {
      display: none;
    }
  }

  @media (max-width: $mobile-width) {
    overflow-x: hidden;

    &__body {

      &::before {
        top: 0;
        right: -2rem;
        width: 6.2rem;
        height: 12rem;
      }
    }

    &__descritpion {
      display: none;

      &--mobile {
        display: block;
        padding: 6.7rem 2rem 7rem;
        font-family: $title-font;
        font-size: 2rem;
        font-weight: 900;
        line-height: 1.333;
        color: $base-dark-color;
        text-transform: uppercase;

        span {
          color: $base-color;
        }
      }
    }

    &__image {
      height: 22rem;
    }

    &__item-hover {
      margin-top: 0.6rem;
    }

    &__item-description {
      font-size: 1.4rem;
    }
  }
}