.button {
  display: inline-block;
  padding: 1.3rem 2.9rem;
  font-size: 2rem;
  font-weight: 500;
  color: $white;
  background-color: $secondary-color;
  border-radius: 8px;

  &--blue {
    color: $base-dark-color;
    background-color: $base-color;
  }

  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

    &:hover,
    &:focus {
      background-color: $secondary-color-hover;
    }

    &--blue {

      &:hover,
      &:focus {
        background-color: $base-color-hover;
      }
    }
  }

  @media (max-width: $tablet-width) {
    padding: 1.1rem 2rem;
    font-size: 1.5rem;
  }
}