.recepie {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 3rem;
  border-radius: 20px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);

  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

    &:hover,
    &:focus {
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.3);

      .recepie__title {
        border-bottom-color: $base-dark-color;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 34rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 2rem;
    border-radius: 20px;

    img {
      display: block;
      border-radius: 20px;
    }
  }

  &__title {
    display: inline-block;
    margin-bottom: 2px;
    font-size: 2rem;
    font-weight: 700;
    color: $base-dark-color;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease-out;
  }

  &__description {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3.6rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__stats {
    display: flex;
  }

  &__stats-item {
    display: flex;
    align-items: center;
    width: auto;
    min-width: 30%;
    color: transparent;

    &:not(:first-child) {
      margin-left: 2rem;
    }

    &:nth-child(2n) {
      width: 8rem;
    }

    svg {
      flex-shrink: 0;
      margin-right: 1rem;
    }
  }

  &__stats-name {
    font-size: 1.2rem;
    color: $black;
  }

  @media (max-width: $tablet-width) {

    &__image {
      margin-bottom: 1.7rem;
    }

    &__title {
      font-size: 1.2rem;
    }

    &__description {
      font-size: 1.2rem;
    }

    &__stats-item {

      &:not(:first-child) {
        margin-left: 1rem;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

    &__stats-name {
      font-size: 1rem;
    }

    @media (max-width: $mobile-width) {

      &__description {
        height: 2.6rem;
      }
    }
  }
}