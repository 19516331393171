.burger-content {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: $base-dark-color;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  transition: all 0.2s ease-out;

  &__body {
    width: 50%;
    height: 100vh;
    padding: 4.5rem 2rem 2rem;
    margin-left: auto;
    background-color: $white;
    overflow: auto;
  }

  &__nav {
    margin-bottom: 5rem;
    font-size: 1.5rem;
  }

  &__nav-item {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  &__contacts-box {
    display: flex;
  }

  &__contacts-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    color: $white;
    background-color: $base-dark-color;
    border-radius: 8px;
  }

  @media (max-width: $tablet-width) {

    &.is-active {
      display: block;
    }
  }
}