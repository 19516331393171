@font-face {
  font-family: 'Futura';
  src:
    url('../fonts/futura/Futura-PT_Demi.woff2') format('woff2'),
    url('../fonts/futura/Futura-PT_Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src:
    url('../fonts/futura/Futura-PT_Heavy.woff2') format('woff2'),
    url('../fonts/futura/Futura-PT_Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Medium Italic'), 
      local('PlayfairDisplay-MediumItalic'), 
      url('../fonts/playfair/Playfairdisplaymediumitalic.woff2') format('woff2'), 
      url('../fonts/playfair/Playfairdisplaymediumitalic.woff') format('woff'), 
      url('../fonts/playfair/Playfairdisplaymediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/Lato-Regular.eot');
    src: local('Lato Regular'), local('Lato-Regular'),
        url('../fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato/Lato-Regular.woff2') format('woff2'),
        url('../fonts/lato/Lato-Regular.woff') format('woff'),
        url('../fonts/lato/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato/Lato-Medium.eot');
    src: local('Lato Medium'), local('Lato-Medium'),
        url('../fonts/lato/Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato/Lato-Medium.woff2') format('woff2'),
        url('../fonts/lato/Lato-Medium.woff') format('woff'),
        url('../fonts/lato/Lato-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Bold.eot');
  src: local('Lato Bold'), local('Lato-Bold'),
  url('../fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/lato/Lato-Bold.woff2') format('woff2'),
  url('../fonts/lato/Lato-Bold.woff') format('woff'),
  url('../fonts/lato/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
