.page-footer {
  color: $base-dark-color;
  background-color: $base-color;

  &__body {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    max-width: 120rem;
    padding: 5rem 0 3.8rem;
    margin: 0 auto;
  }

  &__site-list {
    width: 25rem;
    margin-right: 2.8rem;
    font-size: 2rem;
  }

  &__site-item {
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__contacts {
    width: 38rem;
    margin-right: auto;
  }

  &__contacts-description {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }

  &__contacts-link {
    margin-bottom: 1.6rem;

    a {
      font-size: 2rem;

      svg {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }


  &__info {
    width: 22rem;
    text-align: right;
  }

  &__logo {
    margin-bottom: 3.2rem;
  }

  &__defa-link {
    margin-bottom: 1.2rem;
  }

  &__info-text {
    font-size: 1.2rem;
  }

  &__copyright {
    width: 100%;
    padding-top: 3.8rem;
    margin-top: 7.2rem;
    font-size: 1.2rem;
    border-top: 1px solid $base-dark-color;
  }

  &__copy {

    span {
      display: inline-block;
      margin-left: 3rem;
    }
  }

  @media (max-width: $tablet-width) {

    &__body {
    padding: 3rem 0 2rem;
    }

    &__info {
      order: -1;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $base-dark-color;
    }

    &__defa {
      margin-bottom: 1rem;
    }

    &__info-text {
      max-width: 22rem;
    }

    &__site-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 1.5rem 0;
      font-size: 1.5rem;
      border-bottom: 1px solid $base-dark-color;
    }

    &__site-item {
      margin-bottom: 1.2rem;
    }

    &__contacts {
      width: 100%;
      padding: 1.5rem 0;
    }

    &__contacts-description {
      font-size: 1.5rem;
    }

    &__contacts-link a {
      font-size: 1.5rem;
    }

    &__copyright {
      padding-top: 1.5rem;
      margin-top: 0;
    }
  }

  @media (max-width: $mobile-width) {

    &__body {
      width: 100%;
      padding: 2rem;
      padding-bottom: 1.7rem;
    }

    &__logo {

      svg {
        width: 76px;
        height: 50px;
      }
    }

    &__defa-link {
      width: 106px;
      height: 47px;
    }

    &__defa-link {
      margin-bottom: 0.7rem;
    }

    &__info-text {
      font-size: 1rem;
      max-width: 13.5rem;
    }

    &__site-item:first-child {
      font-weight: 700;
    }

    &__contacts-box {
      display: flex;
    }

    &__contacts-link {
      margin-right: 1rem;
      margin-bottom: 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        color: $white;
        background-color: $base-dark-color;
        border-radius: 8px;

        span {
          display: none;
        }

        svg {
          display: block;
        }
      }
    }

    &__contacts-description {
      font-size: 1.2rem;
    }

    &__copyright {
      font-size: 1rem;
    }
  }
}