.product-page {
  padding-top: 2.5rem;

  &__body {
    width: 85%;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 4rem;
  }

  &__content {
    display: flex;
    margin-bottom: 7.8rem;
  }

  &__info {
    width: 42.75%;
    margin-right: auto;
  }

  &__content-title {
    margin-bottom: 3.6rem;
    font-size: 2rem;
    font-weight: 700;
    color: $base-dark-color;
  }

  &__info-image {
    display: block;
    margin-left: -4rem;
    margin-bottom: 5rem;
    
    img {
      display: block;
    }
  }

  &__stats {
    display: flex;
    color: transparent;
  }

  &__stats-item {
    display: flex;
    align-items: center;

    &:first-child {
      width: 21rem;
      margin-right: 2rem;
    }

    &:nth-child(2n) {
      width: 15rem;
    }

    svg {
      flex-shrink: 0;
      margin-right: 2rem;
    }
  }

  &__stats-name {
    font-size: 1.5rem;
    color: $black;
  }

  &__description {
    width: 57.5%;
  }

  &__ingridients {
    margin-bottom: 6rem;
    font-size: 1.5rem;
  }

  &__ingridients-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;

    &:nth-child(odd) {
      background-color: $lightgray;
    }
  }

  &__ingridients-name {
    color: $black;
  }

  &__ingridients-value {
    color: $base-dark-color;
  }

  &__steps {
    counter-reset: myCounter;
  }

  &__steps-item {
    font-size: 2rem;
    color: $black;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &::before {
      counter-increment: myCounter;
      content:counter(myCounter) '. ';
    }
  }

  @media (max-width: $tablet-width) {
    padding-top: 1.2rem;

    &__title {
      margin-bottom: 2rem;
    }
    
    &__content {
      display: block;
      margin-bottom: 4rem;
    }

    &__content-title {
      margin-bottom: 1.6rem;
      font-size: 1.2rem;
    }

    &__info {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      margin-bottom: 2.6rem;
    }

    &__stats {
      margin-bottom: 2.6rem;
    }

    &__stats-item {

      &:not(:first-child) {
        margin-left: 1rem;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

    &__stats-name {
      font-size: 1rem;
    }

    &__info-image {
      order: -1;
      max-width: 60%;
      margin-left: 0;
      margin-bottom: 0;
    }

    &__description {
      width: 100%;
    }

    &__ingridients {
      margin-bottom: 3rem;
    }

    &__ingridients-item {
      flex-direction: column;
      align-items: flex-start;
      padding: 0.8rem 1rem;
      font-size: 1.2rem;
    }

    &__ingridients-name {
      margin-bottom: 2px;
    }

    &__steps-item {
      font-size: 1.2rem;
    }
  }

  @media (max-width: $mobile-width) {

    &__body {
      width: 100%;
      padding: 0 2rem;
    }
  }
}