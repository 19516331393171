.partners {
  background-color: $lightgray;

  &__body {
    width: 85%;
    max-width: 120rem;
    padding: 6.3rem 0 11rem;
    margin: 0 auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -3rem;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(25% - 3rem*3/4);
    height: 9rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    background-color: $white;
    border-radius: 4px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    img {
      filter: grayscale(100%);
      opacity: 0.5;
      transition: all 0.2s ease-out;
    }

    @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

      &:hover,
      &:focus {

        img {
          filter: none;
          opacity: 1;
        }
      }
    }
  }

  &__title {
    margin-bottom: 4rem;
  }

  @media (max-width: $tablet-width) {

    &__body {
      padding: 3rem 0 5rem;
    }

    &__title {
      margin-bottom: 2rem;
    }

    &__item {
      width: calc(25% - 2rem*3/4);
      height: 7rem;
      padding: 1rem;
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: $mobile-width) {
    display: none;
  }
}