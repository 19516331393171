.product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 4rem 3rem 3rem;
  border-radius: 20px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);

  &.is-action,
  &.is-new {

    &::before {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8rem;
      height: 3rem;
      font-size: 1.5rem;
      color: $white;
      text-transform: uppercase;
      border-radius: 20px;
    }
  }

  &.is-action {

    &::before {
      content: "Акция";
      background-color: $secondary-color;
    }
  }

  &.is-new {

    &::before {
      content: "New";
      background-color: $base-dark-color;
    }
  }

  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

    &:hover,
    &:focus {
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);

      .product__title {
        border-bottom-color: $base-dark-color;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 30rem;
    min-height: 30rem;
    margin: 0 auto;
    margin-bottom: 3.2rem;
  }

  &__title {
    display: inline-block;
    margin-bottom: 2px;
    font-size: 2rem;
    font-weight: 700;
    color: $base-dark-color;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease-out;
  }

  &__description {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3.6rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__weight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 4rem;
    margin-top: auto;
    font-size: 1.5rem;
    color: $base-dark-color;
    background-color: $base-color;
    border-radius: 8px;
  }

  @media (max-width: $tablet-width) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;

    &.is-action,
    &.is-new {

      &::before {
        top: 1rem;
        left: 1rem;
        width: 6rem;
        height: 2rem;
        font-size: 1rem;
      }
    }

    &__image {
      width: 48%;
      min-height: auto;
      margin: 0;
    }

    &__content {
      width: 48%;
    }

    &__title {
      font-size: 1.1rem;
    }

    &__description {
      height: 3rem;
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    &__weight {
      width: 5.6rem;
      height: 2.4rem;
      font-size: 1.2rem;
    }
  }

  @media (max-width: $mobile-width) {

    &__title {
      font-size: 1.2rem;
    }

    &__description {
      font-size: 1rem;
    }
  }
}