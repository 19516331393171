.logo {
  
  img {
    display: block;
  }

  &:active {
    opacity: 0.7;
  }

  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}