.video {
	position: relative;
	width: 100%;
	margin: -1px auto;

	video {
		display: block;
		width: 100%;
		height: auto;

		&.mobile {
			display: none;
		}
	}

	&__content {
		position: absolute;
		z-index: 10;
		bottom: 25%;
		left: 50%;
		transform: translateX(-50%);
	}

	&__button {
		font-weight: 400;
	}

	@media (max-width: $tablet-width) {
		padding: 0;

		&__content {
			bottom: 10%;
		}

		&__button {
			font-size: 1.2rem;
		}
	}

	@media (max-width: $mobile-width) {

		video {
			display: none;

			&.mobile {
				display: block;
				width: 100%;
				height: 30rem;
				object-fit: cover;
			}
		}

		&__content {
			bottom: 4rem;
		}

		&__content {
			width: 100%;
			text-align: center;
		}
	}
}
