// Цвета
$base-color: #97CDE9;
$base-dark-color: #0E3C64;
$base-color-hover: #CCE7F5;
$base-dark-color-hover: #51728F;
$secondary-color: #FF4948;
$secondary-color-hover: #FF6D6D;
$white: #fff;
$black: #171717;
$gray: #A3A3A8;
$lightgray: #F3F3F3;
$base-color-background: rgba(151, 205, 233, 0.5);
$base-color-background-light: rgba(151, 205, 233, 0.3);


// Шрифты
$base-font: 'Lato', 'Arial', sans-serif;
$title-font: 'Futura', 'Arial', sans-serif;
$playfair-italic: 'Playfair Display', 'Times New Roman', serif;

// Вьюпорт
$mobile-width: 767px;
$tablet-width: 1200px;
$retina-dppx: 1.5dppx;
$retina-dpi: 144dpi;

@keyframes marquee {
  from {
    background-position: 0 0;
  }

  to {
      background-position: 60% 0;
  }
}

@keyframes arrows {
  0%{right: 0;}
  50%{right: 50%;}
  100%{right: 100%;}
}