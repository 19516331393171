.intro {
  height: 55rem;

  &--page-header {
    margin-bottom: -27rem;
  }

  &__item {
    position: relative;
  }

  &__image {

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: absolute;
    top: 25%;
    right: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 22.3rem;
    color: $base-dark-color;
  }

  &__title {
    margin-bottom: 1.2rem;
    font-family: $title-font;
    font-size: 4.5rem;
    line-height: 1.333;
    font-weight: 900;
    text-transform: uppercase;
  }

  &__description {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__button {
    margin-top: auto;
  }

  .slick-dots {
    position: absolute;
    bottom: 3rem;
  }

  &__video {
    display: block;
    width: 100%;
    max-height: 55rem;
    object-fit: contain;
  }

  &__video-control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__video-button {
    color: transparent;

    span {
      display: block;
      margin-top: 1rem;
      color: $white;
      text-align: center;
    }
  }

  @media (max-width: $tablet-width) {
    height: auto;

    &--page-header {
      margin-bottom: -7rem;
    }

    &__body {
      padding: 0;
    }

    &__content {
      top: 50%;
      width: 20rem;
      min-height: auto;
      transform: translateY(-50%);
    }

    &__title {
      font-size: 2.5rem;
    }

    &__description {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }


    &__video-control {
      display: none;
    }

    &__video {
      object-fit: cover;
    }
  }

  @media (max-width: $mobile-width) {
    height: 48rem;

    &--page-video {
      height: auto;
    }

    &--page-header {
      height: auto;

      .intro__image {
        height: auto;
      }
    }

    &--mini {
      height: auto;

      .intro__image {
        height: 16rem;
      }
    }

    &__video {
      max-height: calc(12rem + 10vh);
    }

    &__content {
      top: 3.8rem;
      left: 50%;
      right: inherit;
      transform: translateX(-50%);
      width: 80%;
      align-items: center;
      text-align: center;
    }

    &__image {
      height: 48rem;

      img {
        object-fit: cover;
      }
    }

    &__title {
      font-size: 3rem;
    }

    &__video-button {

      svg {
        width: 5.6rem;
        height: 5.6rem;
      }

      span {
        margin-top: 6px;
        font-size: 1.2rem;
      }
    }
  }
}