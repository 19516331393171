.title {
  font-family: $title-font;
  font-size: 3.5rem;
  line-height: 1.333;
  font-weight: 900;
  color: $base-dark-color;
  text-transform: uppercase;

  &--mini {
    font-size: 3rem;
    font-weight: 700;
  }

  @media (max-width: $tablet-width) {
    font-size: 1.8rem;
  }

  @media (max-width: $mobile-width) {
    font-size: 2rem;
  }
}