.page-header {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

  &__body {
    display: flex;
    align-items: center;
    width: 85%;
    max-width: 120rem;
    padding: 3px 0;
    margin: 0 auto;
  }

  &__item {
    flex-grow: 1;
    flex-basis: 0;

    &--logo {
      text-align: center;
    }

    &--nav {
      order: -1;
    }

    &--email {
      font-size: 2rem;
      text-align: right;
    }
  }

  &__burger {
    display: none;

    .is-open {
      display: none;
    }

    &.is-open {

      .is-closed {
        display: none;
      }

      .is-open {
        display: block;
      }
    }
  }

  &__email {
    display: inline-block;
  }

  &__inst {
    display: none;
    color: $base-dark-color;
  }

  @media (max-width: $tablet-width) {

    &__body {
      padding: 1rem 0;
    }

    &__item {
      display: flex;
      align-items: center;
      height: 3rem;

      &--logo {
        flex-basis: auto;
        justify-content: center;
        max-width: 50%;
        text-align: center;
      }

      &--nav {
        order: 1;
      }

      &--email {
        order: -1;
        text-align: left;
      }
    }

    &__burger,
    &__inst {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;

      svg {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &__burger {
      position: relative;
      z-index: 500;
      margin-left: auto;
    }

    &__email {
      display: none;
    }
  }

  @media (max-width: $mobile-width) {

    &__body {
      width: 100%;
      height: 50px;
      padding: 1rem 2rem;
    }

    &__item {

      &--logo {

        a, img {
          display: block;
          width: auto;
          max-height: 2.5rem;
          margin: 0 auto;
        }
      }
    }

    &__burger,
    &__inst {
      height: 2rem;
    }
  }
}
