.recepies {

  &__content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10.6rem;
    display: block;
    width: 85%;
    max-width: 120rem;
  }

  &__title {
    font-family: $title-font;
    font-size: 3rem;
    line-height: 1.333;
    font-weight: 900;
    color: $base-dark-color;
    text-transform: uppercase;
  }

  &__more {
    margin-top: 2.2rem;
  }

  &__image {
    display: block;
    width: 100%;
    height: 55rem;
  }

  @media (max-width: $tablet-width) {

    &__body {
      padding: 0;
    }

    &__content {
      bottom: 3rem;
    }

    &__image {
      height: auto;
    }

    &__title {
      font-size: 2rem;
    }

    &__more {
      font-size: 1.5rem;
    }
  }

  @media (max-width: $mobile-width) {

    &__content {
      top: 3rem;
      width: 100%;
      padding: 0 2rem;
    }

    &__title {
      font-size: 1.8rem;
    }

    &__more {
      margin-top: 1.4rem;
    }
  }
}