.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  color: $base-dark-color;
  border-bottom: 1px solid transparent;

  &::after {
    content: url('../img/icons/arrow-link.svg');
    position: absolute;
    top: 1px;
    right: -17px;
    display: block;
    width: 7px;
    height: 100%;
    margin-left: 10px;
  }

  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

    &:hover,
    &:focus {
      border-bottom-color: $base-dark-color;
    }
  }

  @media (max-width: $mobile-width) {
    &::after {
      right: -14px;
      width: 6px;
    }
  }
}