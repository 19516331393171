.email {
  font-weight: 500;
  color: $base-dark-color;
  border-bottom: 1px solid $base-dark-color;

  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

    &:hover,
    &:focus {
      color: $base-dark-color-hover;
      border-bottom-color: $base-dark-color-hover;
    }
  }
}