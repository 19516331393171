.products {
  position: relative;

  &__body {
    width: 95%;
    max-width: 130rem;
    padding: 6.3rem 5rem 11rem;
    margin: 0 auto;
    background-color: $white;
    border-radius: 20px 20px 0 0;
  }

  &__title {
    margin-bottom: 4rem;
    text-align: center;
  }

  &__nav-list {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }

  &__nav-item {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__nav-link {
    display: inline-block;
    padding: 1.2rem 2rem;
    font-size: 1.5rem;
    color: $base-dark-color;
    text-align: center;
    border: 1px solid $base-dark-color;
    border-radius: 20px;

    &:hover,
    &:focus,
    &.is-active {
      color: $white;
      background-color: $base-dark-color;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -3rem;
  }

  &__item {
    width: calc(33.333% - 3rem*2/3);
    margin-right: 3rem;
    margin-bottom: 3rem;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: $tablet-width) {

    &__body {
      padding: 2.5rem 0 4.5rem;
    }

    &__title {
      margin-bottom: 2rem;
      font-size: 2rem;
    }

    &__nav-list {
      flex-wrap: wrap;
      margin-bottom: 1.5rem;
    }

    &__nav-item {
      margin: 0 0.5rem;
      margin-bottom: 1rem;
    }

    &__nav-link {
      padding: 0.5rem 1.5rem;
      font-size: 1.2rem;
    }

    &__item {
      width: calc(50% - 2rem/2);
      margin-right: 2rem;

      &:nth-child(3n) {
        margin-right: 2rem;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: $mobile-width) {
    margin: 0 2rem;

    &__body {
      width: 100%;
    }

    &__nav-item {
      margin: 0 0.3rem;
      margin-bottom: 1rem;

      &:nth-child(5) {
        order: 1;
      }

      &:nth-child(4) {
        order: 2;
      }

      &:nth-child(6) {
        order: 3;
      }

      &:nth-child(3) {
        order: 4;
      }

      &:nth-child(7) {
        order: 5;
      }
    }

    &__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.5rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}