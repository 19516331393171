.recepies-slider {
  margin-bottom: 8rem;

  &__body {
    width: 85%;
    max-width: 120rem;
    margin: 0 auto;
  }

  &__item {
    padding: 4rem 0 3rem;
  }

  .recepie {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  }

  &__button {
    display: none;
  }

  @media (max-width: $tablet-width) {
    margin-bottom: 5rem;

    .recepie__description {
      font-size: 1rem;
    }
  }

  @media (max-width: $mobile-width) {
    margin-bottom: 3rem;

    &__body {
      width: 100%;
      padding: 0 2rem;
    }

    &__title {
      margin-bottom: 2.5rem;
    }

    &__item {
      padding: 0;
      margin-bottom: 1.5rem;

      &:nth-child(n + 4) {
        display: none;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10rem;
      height: 3rem;
      padding: 7px 13px;
      margin: 3rem auto 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: $white;
      background-color: $base-dark-color;
    }
  }
}