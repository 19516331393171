.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2.5rem;
  font-size: 1.2rem;
  color: $gray;

  &__item {

    &:not(:last-child)::after {
      content: '–';
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media (max-width: $tablet-width) {
    margin-bottom: 2rem;
    font-size: 1rem;

    &__item {

      &:not(:last-child)::after {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  @media (max-width: $mobile-width) {

    &__item {
      margin-bottom: 0.5rem;
    }
  }
}