.table {
  border-radius: 4px;

  &__item {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    padding: 1.1rem 2rem;
    border-radius: inherit;

    &:nth-child(odd) {
      background-color: #f3f3f3;
    }
  }

  &__name {
    color: $black;
  }

  &__value {
    padding-left: 1rem;
    color: $base-dark-color;
  }
}