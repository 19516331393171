.about-us {
  position: relative;
  overflow-x: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 4.7rem;
    right: -7rem;
    display: block;
    width: 33.5rem;
    height: 39rem;
    background-image: url('../img/icons/pattern-voln.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__header {
    width: 85%;
    margin: 0 auto;
    overflow: hidden;
  }

  &__title {
    font-family: $title-font;
    font-size: 3rem;
    line-height: 1.333;
    font-weight: 900;
    color: $base-dark-color;
    text-transform: uppercase;
  }

  &__description {
    padding-top: 6.8rem;
    width: 80%;
    font-family: $title-font;
    font-size: 3rem;
    line-height: 1.333;
    font-weight: 900;
    color: $base-dark-color;
    text-transform: uppercase;

    span {
      color: $base-color;
    }
  }

  &__mission {
    padding-bottom: 11.5rem;
    width: 57rem;
    margin-top: 6rem;
    margin-left: auto;
    margin-right: 17%;
  }

  &__mission-title {
    margin-bottom: 3.7rem;
  }

  &__mission-description {
    font-size: 2.5rem;
    font-weight: 500;
    color: $black;
  }

  &__headline {
    display: flex;
    align-items: center;
    height: 7rem;
    font-family: $title-font;
    font-size: 3rem;
    line-height: 1.333;
    font-weight: 900;
    color: $white;
    text-transform: uppercase;
    background-color: $base-color;

    div {
      display: flex;
      align-items: center;
    }

    img {
      display: block;
      width: 50%;
      padding: 0 5rem;
      object-fit: contain;
      flex-shrink: 0;
      position: relative;
      animation: arrows 15s infinite linear;
      right: -100%;
    }
  }

  &__worth {
    position: relative;
  }

  &__worth-image {
    display: block;
  }

  &__worth-content {
    position: absolute;
    top: 50%;
    left: 12rem;
    transform: translateY(-50%);
    width: 60rem;
    color: $white;
  }

  &__worth-title {
    margin-bottom: 3.7rem;
    color: $white;
  }

  &__worth-list {
    margin-left: 3.5rem;
    font-size: 2.5rem;
    line-height: 1.6;
    font-weight: 500;
    list-style: disc;
  }

  &__vibor {
    padding-top: 4.3rem;
    padding-bottom: 3rem;
    background-color: #D4E8F4;
  }

  &__vibor-title {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  &__vibor-image {
    display: block;
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: $tablet-width) {

    &::after {
      width: 15rem;
      height: 17rem;
    }

    &__title {
      margin-bottom: 2rem;
      font-size: 1.8rem;
    }

    &__description {
      padding-top: 3rem;
      font-size: 1.3rem;
    }

    &__mission {
      width: 100%;
      margin-top: 4rem;
      padding-bottom: 5rem;
    }

    &__mission-description {
      font-size: 1.4rem;
    }

    &__headline {
      height: 4rem;
      font-size: 2rem;

      img {
        padding: 0 2rem;
      }
    }

    &__worth-content {
      width: 85%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__worth-list {
      font-size: 1.3rem;
    }

    &__vibor {
      padding: 3rem 0;
    }

    &__vibor-title {
      padding-bottom: 0;
    }
  }

  @media (max-width: $mobile-width) {

    &::after {
      top: 14rem;
      right: -9rem;
    }

    &__body {
      padding: 0;
    }

    &__header {
      width: 100%;
      padding: 0 2rem;
    }

    &__description {
      width: 95%;
      font-size: 1.8rem;
    }

    &__worth-content {
      top: 5rem;
      left: 0;
      transform: none;
      width: 100%;
      padding: 0 2rem;
    }

    &__worth-title {
      width: 13rem;
    }

    &__worth-list {
      margin-left: 1.5rem;
      font-size: 1.4rem;
      width: 85%;
    }

    &__worth-item {
      margin-bottom: 1rem;
    }

    &__vibor-title {
      width: 85%;
    }
  }
}