.main-nav {

  &__site-list {
    display: flex;
  }

  &__site-item {

    &:not(:last-child) {
      margin-right: 3rem;
    }
  }

  &__site-link {
    display: block;
    padding: 2.8rem 0;
    font-size: 2rem;
    font-weight: 500;
    color: $base-dark-color;
    border-bottom: 4px solid transparent;

    &.is-active {
      border-bottom-color: $base-dark-color;
    }

    @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

      &:hover,
      &:focus {
        border-bottom-color: $base-dark-color;
      }
    }
  }

  @media (max-width: $tablet-width) {
    display: none;
  }
}