.ribbon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 40px;
  font-family: $playfair-italic;
  font-size: 2rem;
  line-height: 1.35;
  font-weight: 500;
  color: $white;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: calc(100% - 20px);
    height: 100%;
    background-color: $base-dark-color;
  }

  &--right {

    .ribbon__text::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border: 20px solid $base-dark-color;
      border-right: 10px solid transparent;
    }
  }

  &__text {
    font-size: 2rem;
    font-style: italic;
  }

  &--small {

    .ribbon__text {
      font-size: 1.2rem;
    }
  }

  &--medium {
    height: 60px;
    padding: 10px 30px;

    .ribbon__text {
      font-size: 2.7rem;
    }

    &.ribbon--right {

      .ribbon__text::after {
        border-width: 30px;
        border-right-width: 12px;
      }
    }

    &.ribbon--left {

      .ribbon__text::before {
        border-width: 30px;
        border-left-width: 12px;
      }
    }
  }

  @media (max-width: $mobile-width) {
    width: 20rem;
    height: 30px;

    &__text {
      font-size: 1.5rem;
    }

    &.ribbon--right {

      .ribbon__text::after {
        border: 15px solid $base-dark-color;
        border-right: 6px solid transparent;
      }
    }
  }
}
