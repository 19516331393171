.instagram {

  &__body {
    width: 85%;
    max-width: 120rem;
    padding: 6.3rem 0 11rem;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 4rem;

    a {
      
      @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $tablet-width) {
    overflow-x: hidden;

    &__body {
      padding: 3rem 0 5rem;
    }

    &__title {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: $mobile-width) {

    &__body {
      width: 100%;
      padding: 3rem 2rem 4.5rem;
      margin: 0;
    }

    &__title {
      margin-bottom: 1.4rem;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      margin-bottom: -1rem;
    }

    &__item {
      width: calc(50% - 1rem/2);
      height: auto;
      margin-left: 0;
      margin-right: 1rem;
      margin-bottom: 1rem;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}