.good-page {

  &__body {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    max-width: 120rem;
    margin: 0 auto;
  }

  &__slider {
    position: relative;
    width: 50%;
    max-width: 58rem;
    height: 58rem;
  }

  &__image-nav {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    max-width: 11.3rem;
    height: 100%;
    margin: -1rem -2rem;
  }

  &__image-nav-item {
    width: 7.3rem;
    height: 7.3rem;
    margin: 1rem 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    cursor: pointer;
  }

  &__image-nav-picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }

  &__image-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .slick-list {
      width: 100%;
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }
  }

  &__image-main-item {

    &.slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__image-main-picture {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &__description {
    width: 50%;
    max-width: 50rem;
    margin-left: 3.6rem;
  }

  &__description-item:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__description-title {
    margin-bottom: 1.1rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: $base-dark-color;
  }

  &__description-value {
    font-size: 1.5rem;
    color: $black;
  }

  &__stores {
    display: flex;
    flex-wrap: wrap;
  }

  &__stores-item {
    width: 6.2rem;
    height: 6.2rem;
    margin-bottom: 2rem;
    margin-right: 2.5rem;

    &:nth-child(6n) {
      margin-right: 0;
    }
  }
  
  &__stores-link {
    display: block;

    @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
  }

  &__content {
    margin: 6rem 0 6.8rem;
  }

  &__about-list {
    display: flex;
    flex-wrap: wrap;
    counter-reset: productCounter;
    margin-bottom: -2rem;
  }

  &__about-item {
    width: calc(50% - 3rem/2);
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    font-size: 2rem;
    color: $black;

    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 5rem;
      height: 5rem;
      margin-right: 2.3rem;
      font-family: $title-font;
      font-size: 3rem;
      font-weight: 700;
      color: $white;
      border-radius: 50%;
      background-color: #003764;
      counter-increment: productCounter;
      content: counter(productCounter);
    }

    &:nth-child(even) {
      margin-left: 3rem;
    }
  }
}

.slider__item a {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.recepies-slider__list .slick-dots {
  bottom: -1rem;
}

.page-footer__insta a {

  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}

.title--mini {
  font-size: 3rem;
  font-weight: 700;
}

.page-footer__insta {
  margin: 0 3rem;
}

.page-footer__contacts {
  margin-left: auto;
  margin-right: 0;
}

.page-header {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}