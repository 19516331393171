@charset "UTF-8";
@font-face {
  font-family: 'Futura';
  src: url("../fonts/futura/Futura-PT_Demi.woff2") format("woff2"), url("../fonts/futura/Futura-PT_Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/futura/Futura-PT_Heavy.woff2") format("woff2"), url("../fonts/futura/Futura-PT_Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Playfair Display';
  src: local("Playfair Display Medium Italic"), local("PlayfairDisplay-MediumItalic"), url("../fonts/playfair/Playfairdisplaymediumitalic.woff2") format("woff2"), url("../fonts/playfair/Playfairdisplaymediumitalic.woff") format("woff"), url("../fonts/playfair/Playfairdisplaymediumitalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Regular.eot");
  src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Regular.woff2") format("woff2"), url("../fonts/lato/Lato-Regular.woff") format("woff"), url("../fonts/lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Medium.eot");
  src: local("Lato Medium"), local("Lato-Medium"), url("../fonts/lato/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Medium.woff2") format("woff2"), url("../fonts/lato/Lato-Medium.woff") format("woff"), url("../fonts/lato/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Bold.eot");
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Bold.woff2") format("woff2"), url("../fonts/lato/Lato-Bold.woff") format("woff"), url("../fonts/lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@keyframes marquee {
  from {
    background-position: 0 0; }
  to {
    background-position: 60% 0; } }

@keyframes arrows {
  0% {
    right: 0; }
  50% {
    right: 50%; }
  100% {
    right: 100%; } }

html {
  font-size: 10px; }

body {
  position: relative;
  min-width: 320px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  color: #171717;
  font-style: normal; }

body.lock {
  overflow: hidden; }

.overflow {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8); }

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  outline: none; }

*::placeholder {
  color: rgba(57, 70, 79, 0.7); }

img,
svg {
  display: inline-block;
  max-width: 100%;
  height: auto; }

svg {
  fill: currentColor; }

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease-out; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

button {
  font: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 0.2s ease-out; }

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0, 0, 0, 0);
  overflow: hidden; }

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto; }
  .wrapper--row {
    display: flex;
    align-items: center; }

@media (min-width: 320px) and (max-width: 1200px) {
  html {
    font-size: calc(10px + (20 - 10) * ((100vw - 320px) / (1200 - 320))); } }

@media (max-width: 1200px) {
  main {
    position: relative; } }

@media (max-width: 767px) {
  .wrapper {
    padding: 0 15px; } }

.page-header {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15); }
  .page-header__body {
    display: flex;
    align-items: center;
    width: 85%;
    max-width: 120rem;
    padding: 3px 0;
    margin: 0 auto; }
  .page-header__item {
    flex-grow: 1;
    flex-basis: 0; }
    .page-header__item--logo {
      text-align: center; }
    .page-header__item--nav {
      order: -1; }
    .page-header__item--email {
      font-size: 2rem;
      text-align: right; }
  .page-header__burger {
    display: none; }
    .page-header__burger .is-open {
      display: none; }
    .page-header__burger.is-open .is-closed {
      display: none; }
    .page-header__burger.is-open .is-open {
      display: block; }
  .page-header__email {
    display: inline-block; }
  .page-header__inst {
    display: none;
    color: #0E3C64; }
  @media (max-width: 1200px) {
    .page-header__body {
      padding: 1rem 0; }
    .page-header__item {
      display: flex;
      align-items: center;
      height: 3rem; }
      .page-header__item--logo {
        flex-basis: auto;
        justify-content: center;
        max-width: 50%;
        text-align: center; }
      .page-header__item--nav {
        order: 1; }
      .page-header__item--email {
        order: -1;
        text-align: left; }
    .page-header__burger, .page-header__inst {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem; }
      .page-header__burger svg, .page-header__inst svg {
        display: block;
        width: 2.5rem;
        height: 2.5rem; }
    .page-header__burger {
      position: relative;
      z-index: 500;
      margin-left: auto; }
    .page-header__email {
      display: none; } }
  @media (max-width: 767px) {
    .page-header__body {
      width: 100%;
      height: 50px;
      padding: 1rem 2rem; }
    .page-header__item--logo a, .page-header__item--logo img {
      display: block;
      width: auto;
      max-height: 2.5rem;
      margin: 0 auto; }
    .page-header__burger, .page-header__inst {
      height: 2rem; } }

.page-footer {
  color: #0E3C64;
  background-color: #97CDE9; }
  .page-footer__body {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    max-width: 120rem;
    padding: 5rem 0 3.8rem;
    margin: 0 auto; }
  .page-footer__site-list {
    width: 25rem;
    margin-right: 2.8rem;
    font-size: 2rem; }
  .page-footer__site-item {
    margin-bottom: 1.6rem; }
    .page-footer__site-item:last-child {
      margin-bottom: 0; }
  .page-footer__contacts {
    width: 38rem;
    margin-right: auto; }
  .page-footer__contacts-description {
    font-size: 1.8rem;
    margin-bottom: 1.2rem; }
  .page-footer__contacts-link {
    margin-bottom: 1.6rem; }
    .page-footer__contacts-link a {
      font-size: 2rem; }
      .page-footer__contacts-link a svg {
        display: none; }
    .page-footer__contacts-link:last-child {
      margin-bottom: 0; }
  .page-footer__info {
    width: 22rem;
    text-align: right; }
  .page-footer__logo {
    margin-bottom: 3.2rem; }
  .page-footer__defa-link {
    margin-bottom: 1.2rem; }
  .page-footer__info-text {
    font-size: 1.2rem; }
  .page-footer__copyright {
    width: 100%;
    padding-top: 3.8rem;
    margin-top: 7.2rem;
    font-size: 1.2rem;
    border-top: 1px solid #0E3C64; }
  .page-footer__copy span {
    display: inline-block;
    margin-left: 3rem; }
  @media (max-width: 1200px) {
    .page-footer__body {
      padding: 3rem 0 2rem; }
    .page-footer__info {
      order: -1;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #0E3C64; }
    .page-footer__defa {
      margin-bottom: 1rem; }
    .page-footer__info-text {
      max-width: 22rem; }
    .page-footer__site-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 1.5rem 0;
      font-size: 1.5rem;
      border-bottom: 1px solid #0E3C64; }
    .page-footer__site-item {
      margin-bottom: 1.2rem; }
    .page-footer__contacts {
      width: 100%;
      padding: 1.5rem 0; }
    .page-footer__contacts-description {
      font-size: 1.5rem; }
    .page-footer__contacts-link a {
      font-size: 1.5rem; }
    .page-footer__copyright {
      padding-top: 1.5rem;
      margin-top: 0; } }
  @media (max-width: 767px) {
    .page-footer__body {
      width: 100%;
      padding: 2rem;
      padding-bottom: 1.7rem; }
    .page-footer__logo svg {
      width: 76px;
      height: 50px; }
    .page-footer__defa-link {
      width: 106px;
      height: 47px; }
    .page-footer__defa-link {
      margin-bottom: 0.7rem; }
    .page-footer__info-text {
      font-size: 1rem;
      max-width: 13.5rem; }
    .page-footer__site-item:first-child {
      font-weight: 700; }
    .page-footer__contacts-box {
      display: flex; }
    .page-footer__contacts-link {
      margin-right: 1rem;
      margin-bottom: 0; }
      .page-footer__contacts-link a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        color: #fff;
        background-color: #0E3C64;
        border-radius: 8px; }
        .page-footer__contacts-link a span {
          display: none; }
        .page-footer__contacts-link a svg {
          display: block; }
    .page-footer__contacts-description {
      font-size: 1.2rem; }
    .page-footer__copyright {
      font-size: 1rem; } }

.burger-content {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: #0E3C64;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  transition: all 0.2s ease-out; }
  .burger-content__body {
    width: 50%;
    height: 100vh;
    padding: 4.5rem 2rem 2rem;
    margin-left: auto;
    background-color: #fff;
    overflow: auto; }
  .burger-content__nav {
    margin-bottom: 5rem;
    font-size: 1.5rem; }
  .burger-content__nav-item {
    margin-bottom: 1rem; }
    .burger-content__nav-item:last-child {
      margin-bottom: 0; }
  .burger-content__description {
    font-size: 1rem;
    margin-bottom: 1.5rem; }
  .burger-content__contacts-box {
    display: flex; }
  .burger-content__contacts-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    color: #fff;
    background-color: #0E3C64;
    border-radius: 8px; }
  @media (max-width: 1200px) {
    .burger-content.is-active {
      display: block; } }

.email {
  font-weight: 500;
  color: #0E3C64;
  border-bottom: 1px solid #0E3C64; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .email:hover, .email:focus {
      color: #51728F;
      border-bottom-color: #51728F; } }

.logo img {
  display: block; }

.logo:active {
  opacity: 0.7; }

@media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
  .logo:hover, .logo:focus {
    opacity: 0.7; } }

.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  color: #0E3C64;
  border-bottom: 1px solid transparent; }
  .link::after {
    content: url("../img/icons/arrow-link.svg");
    position: absolute;
    top: 1px;
    right: -17px;
    display: block;
    width: 7px;
    height: 100%;
    margin-left: 10px; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .link:hover, .link:focus {
      border-bottom-color: #0E3C64; } }
  @media (max-width: 767px) {
    .link::after {
      right: -14px;
      width: 6px; } }

.button {
  display: inline-block;
  padding: 1.3rem 2.9rem;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  background-color: #FF4948;
  border-radius: 8px; }
  .button--blue {
    color: #0E3C64;
    background-color: #97CDE9; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .button:hover, .button:focus {
      background-color: #FF6D6D; }
    .button--blue:hover, .button--blue:focus {
      background-color: #CCE7F5; } }
  @media (max-width: 1200px) {
    .button {
      padding: 1.1rem 2rem;
      font-size: 1.5rem; } }

.ribbon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 40px;
  font-family: "Playfair Display", "Times New Roman", serif;
  font-size: 2rem;
  line-height: 1.35;
  font-weight: 500;
  color: #fff; }
  .ribbon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: calc(100% - 20px);
    height: 100%;
    background-color: #0E3C64; }
  .ribbon--right .ribbon__text::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border: 20px solid #0E3C64;
    border-right: 10px solid transparent; }
  .ribbon__text {
    font-size: 2rem;
    font-style: italic; }
  .ribbon--small .ribbon__text {
    font-size: 1.2rem; }
  .ribbon--medium {
    height: 60px;
    padding: 10px 30px; }
    .ribbon--medium .ribbon__text {
      font-size: 2.7rem; }
    .ribbon--medium.ribbon--right .ribbon__text::after {
      border-width: 30px;
      border-right-width: 12px; }
    .ribbon--medium.ribbon--left .ribbon__text::before {
      border-width: 30px;
      border-left-width: 12px; }
  @media (max-width: 767px) {
    .ribbon {
      width: 20rem;
      height: 30px; }
      .ribbon__text {
        font-size: 1.5rem; }
      .ribbon.ribbon--right .ribbon__text::after {
        border: 15px solid #0E3C64;
        border-right: 6px solid transparent; } }

.title {
  font-family: "Futura", "Arial", sans-serif;
  font-size: 3.5rem;
  line-height: 1.333;
  font-weight: 900;
  color: #0E3C64;
  text-transform: uppercase; }
  .title--mini {
    font-size: 3rem;
    font-weight: 700; }
  @media (max-width: 1200px) {
    .title {
      font-size: 1.8rem; } }
  @media (max-width: 767px) {
    .title {
      font-size: 2rem; } }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2.5rem;
  font-size: 1.2rem;
  color: #A3A3A8; }
  .breadcrumbs__item:not(:last-child)::after {
    content: '–';
    margin-left: 10px;
    margin-right: 10px; }
  @media (max-width: 1200px) {
    .breadcrumbs {
      margin-bottom: 2rem;
      font-size: 1rem; }
      .breadcrumbs__item:not(:last-child)::after {
        margin-left: 5px;
        margin-right: 5px; } }
  @media (max-width: 767px) {
    .breadcrumbs__item {
      margin-bottom: 0.5rem; } }

.slider {
  margin: 0 -1.5rem; }
  .slider__item {
    margin: 0 1.5rem; }
  .slider .slick-dots {
    position: absolute;
    bottom: -4rem; }
  .slider .slick-prev {
    left: -4rem; }
  .slider .slick-next {
    right: -4rem; }
  @media (max-width: 1200px) {
    .slider {
      margin: 0 -1rem; }
      .slider__item {
        margin: 0 1rem; }
      .slider .slick-prev {
        left: -1.5rem; }
      .slider .slick-next {
        right: -2.5rem; }
      .slider .slick-dots {
        position: absolute;
        bottom: -3rem; } }

.main-nav__site-list {
  display: flex; }

.main-nav__site-item:not(:last-child) {
  margin-right: 3rem; }

.main-nav__site-link {
  display: block;
  padding: 2.8rem 0;
  font-size: 2rem;
  font-weight: 500;
  color: #0E3C64;
  border-bottom: 4px solid transparent; }
  .main-nav__site-link.is-active {
    border-bottom-color: #0E3C64; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .main-nav__site-link:hover, .main-nav__site-link:focus {
      border-bottom-color: #0E3C64; } }

@media (max-width: 1200px) {
  .main-nav {
    display: none; } }

.intro {
  height: 55rem; }
  .intro--page-header {
    margin-bottom: -27rem; }
  .intro__item {
    position: relative; }
  .intro__image img {
    width: 100%;
    height: 100%; }
  .intro__content {
    position: absolute;
    top: 25%;
    right: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 22.3rem;
    color: #0E3C64; }
  .intro__title {
    margin-bottom: 1.2rem;
    font-family: "Futura", "Arial", sans-serif;
    font-size: 4.5rem;
    line-height: 1.333;
    font-weight: 900;
    text-transform: uppercase; }
  .intro__description {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase; }
  .intro__button {
    margin-top: auto; }
  .intro .slick-dots {
    position: absolute;
    bottom: 3rem; }
  .intro__video {
    display: block;
    width: 100%;
    max-height: 55rem;
    object-fit: contain; }
  .intro__video-control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .intro__video-button {
    color: transparent; }
    .intro__video-button span {
      display: block;
      margin-top: 1rem;
      color: #fff;
      text-align: center; }
  @media (max-width: 1200px) {
    .intro {
      height: auto; }
      .intro--page-header {
        margin-bottom: -7rem; }
      .intro__body {
        padding: 0; }
      .intro__content {
        top: 50%;
        width: 20rem;
        min-height: auto;
        transform: translateY(-50%); }
      .intro__title {
        font-size: 2.5rem; }
      .intro__description {
        margin-bottom: 1rem;
        font-size: 1.5rem; }
      .intro__video-control {
        display: none; }
      .intro__video {
        object-fit: cover; } }
  @media (max-width: 767px) {
    .intro {
      height: 48rem; }
      .intro--page-video {
        height: auto; }
      .intro--page-header {
        height: auto; }
        .intro--page-header .intro__image {
          height: auto; }
      .intro--mini {
        height: auto; }
        .intro--mini .intro__image {
          height: 16rem; }
      .intro__video {
        max-height: calc(12rem + 10vh); }
      .intro__content {
        top: 3.8rem;
        left: 50%;
        right: inherit;
        transform: translateX(-50%);
        width: 80%;
        align-items: center;
        text-align: center; }
      .intro__image {
        height: 48rem; }
        .intro__image img {
          object-fit: cover; }
      .intro__title {
        font-size: 3rem; }
      .intro__video-button svg {
        width: 5.6rem;
        height: 5.6rem; }
      .intro__video-button span {
        margin-top: 6px;
        font-size: 1.2rem; } }

.advantages__body {
  position: relative; }
  .advantages__body::before {
    content: '';
    position: absolute;
    top: -10rem;
    right: 12rem;
    display: block;
    width: 17.5rem;
    height: 34rem;
    background-image: url("../img/icons/pattern.svg"); }

.advantages__descritpion {
  padding: 12rem 0 15rem;
  width: 85%;
  max-width: 120rem;
  margin: 0 auto;
  font-family: "Futura", "Arial", sans-serif;
  font-size: 3rem;
  font-weight: 900;
  line-height: 1.333;
  color: #0E3C64;
  text-transform: uppercase; }
  .advantages__descritpion span {
    color: #97CDE9; }
  .advantages__descritpion--mobile {
    display: none; }

.advantages__list {
  display: flex;
  height: 58rem; }

.advantages__item {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  background-color: rgba(151, 205, 233, 0.5); }
  .advantages__item:nth-child(2) {
    background-color: rgba(151, 205, 233, 0.3); }
  .advantages__item:not(:last-child)::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 73.75%;
    transform: translateY(-50%);
    display: block;
    width: 25.5rem;
    height: 33.5rem;
    background-image: url("../img/icons/ampersand.svg");
    opacity: 0.5; }

.advantages__item-link {
  display: block;
  width: 100%;
  height: 100%; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .advantages__item-link:hover .advantages__item-hover, .advantages__item-link:focus .advantages__item-hover {
      opacity: 1; } }

.advantages__item-title {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%); }

.advantages__item-description {
  width: 100%; }

.advantages__image {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.advantages__item-hover {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10rem 9rem;
  padding-bottom: 15.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  transition: all 0.3s ease-out; }

.advantages__item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%; }

.advantages__item-description {
  margin-bottom: auto;
  font-size: 1.8rem;
  color: #171717; }

@media (max-width: 1200px) {
  .advantages__body {
    padding: 0; }
    .advantages__body::before {
      top: -3rem;
      right: 3rem;
      width: 8.75rem;
      height: 17rem; }
  .advantages__descritpion {
    padding: 7rem 0;
    font-size: 2rem; }
  .advantages__list {
    display: block;
    height: auto; }
  .advantages__item:not(:last-child)::after {
    display: none; }
  .advantages__item-link {
    padding: 4rem 2rem; }
  .advantages__item-title {
    left: 0;
    transform: none; }
  .advantages__image {
    height: 30rem; }
    .advantages__image img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .advantages__item-hover {
    position: relative;
    margin-top: 3rem;
    padding: 0;
    background-color: transparent;
    opacity: 1; }
  .advantages__item-description {
    font-size: 1.6rem; }
  .advantages__item-more {
    display: none; } }

@media (max-width: 767px) {
  .advantages {
    overflow-x: hidden; }
    .advantages__body::before {
      top: 0;
      right: -2rem;
      width: 6.2rem;
      height: 12rem; }
    .advantages__descritpion {
      display: none; }
      .advantages__descritpion--mobile {
        display: block;
        padding: 6.7rem 2rem 7rem;
        font-family: "Futura", "Arial", sans-serif;
        font-size: 2rem;
        font-weight: 900;
        line-height: 1.333;
        color: #0E3C64;
        text-transform: uppercase; }
        .advantages__descritpion--mobile span {
          color: #97CDE9; }
    .advantages__image {
      height: 22rem; }
    .advantages__item-hover {
      margin-top: 0.6rem; }
    .advantages__item-description {
      font-size: 1.4rem; } }

.video {
  position: relative;
  width: 100%;
  margin: -1px auto; }
  .video video {
    display: block;
    width: 100%;
    height: auto; }
    .video video.mobile {
      display: none; }
  .video__content {
    position: absolute;
    z-index: 10;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%); }
  .video__button {
    font-weight: 400; }
  @media (max-width: 1200px) {
    .video {
      padding: 0; }
      .video__content {
        bottom: 10%; }
      .video__button {
        font-size: 1.2rem; } }
  @media (max-width: 767px) {
    .video video {
      display: none; }
      .video video.mobile {
        display: block;
        width: 100%;
        height: 30rem;
        object-fit: cover; }
    .video__content {
      bottom: 4rem; }
    .video__content {
      width: 100%;
      text-align: center; } }

.recepies__content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10.6rem;
  display: block;
  width: 85%;
  max-width: 120rem; }

.recepies__title {
  font-family: "Futura", "Arial", sans-serif;
  font-size: 3rem;
  line-height: 1.333;
  font-weight: 900;
  color: #0E3C64;
  text-transform: uppercase; }

.recepies__more {
  margin-top: 2.2rem; }

.recepies__image {
  display: block;
  width: 100%;
  height: 55rem; }

@media (max-width: 1200px) {
  .recepies__body {
    padding: 0; }
  .recepies__content {
    bottom: 3rem; }
  .recepies__image {
    height: auto; }
  .recepies__title {
    font-size: 2rem; }
  .recepies__more {
    font-size: 1.5rem; } }

@media (max-width: 767px) {
  .recepies__content {
    top: 3rem;
    width: 100%;
    padding: 0 2rem; }
  .recepies__title {
    font-size: 1.8rem; }
  .recepies__more {
    margin-top: 1.4rem; } }

.instagram__body {
  width: 85%;
  max-width: 120rem;
  padding: 6.3rem 0 11rem;
  margin: 0 auto; }

.instagram__title {
  margin-bottom: 4rem; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .instagram__title a:hover, .instagram__title a:focus {
      text-decoration: underline; } }

.instagram__link {
  display: block;
  width: 100%;
  height: 100%; }
  .instagram__link img {
    width: 100%;
    height: 100%; }

@media (max-width: 1200px) {
  .instagram {
    overflow-x: hidden; }
    .instagram__body {
      padding: 3rem 0 5rem; }
    .instagram__title {
      margin-bottom: 2rem; } }

@media (max-width: 767px) {
  .instagram__body {
    width: 100%;
    padding: 3rem 2rem 4.5rem;
    margin: 0; }
  .instagram__title {
    margin-bottom: 1.4rem; }
  .instagram__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: -1rem; }
  .instagram__item {
    width: calc(50% - 1rem/2);
    height: auto;
    margin-left: 0;
    margin-right: 1rem;
    margin-bottom: 1rem; }
    .instagram__item:nth-child(2n) {
      margin-right: 0; } }

.partners {
  background-color: #F3F3F3; }
  .partners__body {
    width: 85%;
    max-width: 120rem;
    padding: 6.3rem 0 11rem;
    margin: 0 auto; }
  .partners__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -3rem; }
  .partners__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(25% - 3rem*3/4);
    height: 9rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    background-color: #fff;
    border-radius: 4px; }
    .partners__item:nth-child(4n) {
      margin-right: 0; }
    .partners__item img {
      filter: grayscale(100%);
      opacity: 0.5;
      transition: all 0.2s ease-out; }
    @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
      .partners__item:hover img, .partners__item:focus img {
        filter: none;
        opacity: 1; } }
  .partners__title {
    margin-bottom: 4rem; }
  @media (max-width: 1200px) {
    .partners__body {
      padding: 3rem 0 5rem; }
    .partners__title {
      margin-bottom: 2rem; }
    .partners__item {
      width: calc(25% - 2rem*3/4);
      height: 7rem;
      padding: 1rem;
      margin-right: 2rem;
      margin-bottom: 2rem; } }
  @media (max-width: 767px) {
    .partners {
      display: none; } }

.products {
  position: relative; }
  .products__body {
    width: 95%;
    max-width: 130rem;
    padding: 6.3rem 5rem 11rem;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 20px 20px 0 0; }
  .products__title {
    margin-bottom: 4rem;
    text-align: center; }
  .products__nav-list {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem; }
  .products__nav-item {
    margin-right: 1rem; }
    .products__nav-item:last-child {
      margin-right: 0; }
  .products__nav-link {
    display: inline-block;
    padding: 1.2rem 2rem;
    font-size: 1.5rem;
    color: #0E3C64;
    text-align: center;
    border: 1px solid #0E3C64;
    border-radius: 20px; }
    .products__nav-link:hover, .products__nav-link:focus, .products__nav-link.is-active {
      color: #fff;
      background-color: #0E3C64; }
  .products__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -3rem; }
  .products__item {
    width: calc(33.333% - 3rem*2/3);
    margin-right: 3rem;
    margin-bottom: 3rem; }
    .products__item:nth-child(3n) {
      margin-right: 0; }
  @media (max-width: 1200px) {
    .products__body {
      padding: 2.5rem 0 4.5rem; }
    .products__title {
      margin-bottom: 2rem;
      font-size: 2rem; }
    .products__nav-list {
      flex-wrap: wrap;
      margin-bottom: 1.5rem; }
    .products__nav-item {
      margin: 0 0.5rem;
      margin-bottom: 1rem; }
    .products__nav-link {
      padding: 0.5rem 1.5rem;
      font-size: 1.2rem; }
    .products__item {
      width: calc(50% - 2rem/2);
      margin-right: 2rem; }
      .products__item:nth-child(3n) {
        margin-right: 2rem; }
      .products__item:nth-child(2n) {
        margin-right: 0; } }
  @media (max-width: 767px) {
    .products {
      margin: 0 2rem; }
      .products__body {
        width: 100%; }
      .products__nav-item {
        margin: 0 0.3rem;
        margin-bottom: 1rem; }
        .products__nav-item:nth-child(5) {
          order: 1; }
        .products__nav-item:nth-child(4) {
          order: 2; }
        .products__nav-item:nth-child(6) {
          order: 3; }
        .products__nav-item:nth-child(3) {
          order: 4; }
        .products__nav-item:nth-child(7) {
          order: 5; }
      .products__item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.5rem; }
        .products__item:nth-child(3n) {
          margin-right: 0; } }

.product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 4rem 3rem 3rem;
  border-radius: 20px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15); }
  .product.is-action::before, .product.is-new::before {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 3rem;
    font-size: 1.5rem;
    color: #fff;
    text-transform: uppercase;
    border-radius: 20px; }
  .product.is-action::before {
    content: "Акция";
    background-color: #FF4948; }
  .product.is-new::before {
    content: "New";
    background-color: #0E3C64; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .product:hover, .product:focus {
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3); }
      .product:hover .product__title, .product:focus .product__title {
        border-bottom-color: #0E3C64; } }
  .product__image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 30rem;
    min-height: 30rem;
    margin: 0 auto;
    margin-bottom: 3.2rem; }
  .product__title {
    display: inline-block;
    margin-bottom: 2px;
    font-size: 2rem;
    font-weight: 700;
    color: #0E3C64;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease-out; }
  .product__description {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3.6rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden; }
  .product__weight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 4rem;
    margin-top: auto;
    font-size: 1.5rem;
    color: #0E3C64;
    background-color: #97CDE9;
    border-radius: 8px; }
  @media (max-width: 1200px) {
    .product {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem; }
      .product.is-action::before, .product.is-new::before {
        top: 1rem;
        left: 1rem;
        width: 6rem;
        height: 2rem;
        font-size: 1rem; }
      .product__image {
        width: 48%;
        min-height: auto;
        margin: 0; }
      .product__content {
        width: 48%; }
      .product__title {
        font-size: 1.1rem; }
      .product__description {
        height: 3rem;
        margin-bottom: 1rem;
        font-size: 1rem; }
      .product__weight {
        width: 5.6rem;
        height: 2.4rem;
        font-size: 1.2rem; } }
  @media (max-width: 767px) {
    .product__title {
      font-size: 1.2rem; }
    .product__description {
      font-size: 1rem; } }

.about-us {
  position: relative;
  overflow-x: hidden; }
  .about-us::after {
    content: '';
    position: absolute;
    top: 4.7rem;
    right: -7rem;
    display: block;
    width: 33.5rem;
    height: 39rem;
    background-image: url("../img/icons/pattern-voln.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
  .about-us__header {
    width: 85%;
    margin: 0 auto;
    overflow: hidden; }
  .about-us__title {
    font-family: "Futura", "Arial", sans-serif;
    font-size: 3rem;
    line-height: 1.333;
    font-weight: 900;
    color: #0E3C64;
    text-transform: uppercase; }
  .about-us__description {
    padding-top: 6.8rem;
    width: 80%;
    font-family: "Futura", "Arial", sans-serif;
    font-size: 3rem;
    line-height: 1.333;
    font-weight: 900;
    color: #0E3C64;
    text-transform: uppercase; }
    .about-us__description span {
      color: #97CDE9; }
  .about-us__mission {
    padding-bottom: 11.5rem;
    width: 57rem;
    margin-top: 6rem;
    margin-left: auto;
    margin-right: 17%; }
  .about-us__mission-title {
    margin-bottom: 3.7rem; }
  .about-us__mission-description {
    font-size: 2.5rem;
    font-weight: 500;
    color: #171717; }
  .about-us__headline {
    display: flex;
    align-items: center;
    height: 7rem;
    font-family: "Futura", "Arial", sans-serif;
    font-size: 3rem;
    line-height: 1.333;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    background-color: #97CDE9; }
    .about-us__headline div {
      display: flex;
      align-items: center; }
    .about-us__headline img {
      display: block;
      width: 50%;
      padding: 0 5rem;
      object-fit: contain;
      flex-shrink: 0;
      position: relative;
      animation: arrows 15s infinite linear;
      right: -100%; }
  .about-us__worth {
    position: relative; }
  .about-us__worth-image {
    display: block; }
  .about-us__worth-content {
    position: absolute;
    top: 50%;
    left: 12rem;
    transform: translateY(-50%);
    width: 60rem;
    color: #fff; }
  .about-us__worth-title {
    margin-bottom: 3.7rem;
    color: #fff; }
  .about-us__worth-list {
    margin-left: 3.5rem;
    font-size: 2.5rem;
    line-height: 1.6;
    font-weight: 500;
    list-style: disc; }
  .about-us__vibor {
    padding-top: 4.3rem;
    padding-bottom: 3rem;
    background-color: #D4E8F4; }
  .about-us__vibor-title {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 1rem; }
  .about-us__vibor-image {
    display: block;
    width: 90%;
    margin: 0 auto; }
  @media (max-width: 1200px) {
    .about-us::after {
      width: 15rem;
      height: 17rem; }
    .about-us__title {
      margin-bottom: 2rem;
      font-size: 1.8rem; }
    .about-us__description {
      padding-top: 3rem;
      font-size: 1.3rem; }
    .about-us__mission {
      width: 100%;
      margin-top: 4rem;
      padding-bottom: 5rem; }
    .about-us__mission-description {
      font-size: 1.4rem; }
    .about-us__headline {
      height: 4rem;
      font-size: 2rem; }
      .about-us__headline img {
        padding: 0 2rem; }
    .about-us__worth-content {
      width: 85%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .about-us__worth-list {
      font-size: 1.3rem; }
    .about-us__vibor {
      padding: 3rem 0; }
    .about-us__vibor-title {
      padding-bottom: 0; } }
  @media (max-width: 767px) {
    .about-us::after {
      top: 14rem;
      right: -9rem; }
    .about-us__body {
      padding: 0; }
    .about-us__header {
      width: 100%;
      padding: 0 2rem; }
    .about-us__description {
      width: 95%;
      font-size: 1.8rem; }
    .about-us__worth-content {
      top: 5rem;
      left: 0;
      transform: none;
      width: 100%;
      padding: 0 2rem; }
    .about-us__worth-title {
      width: 13rem; }
    .about-us__worth-list {
      margin-left: 1.5rem;
      font-size: 1.4rem;
      width: 85%; }
    .about-us__worth-item {
      margin-bottom: 1rem; }
    .about-us__vibor-title {
      width: 85%; } }

.recepie {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 3rem;
  border-radius: 20px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15); }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .recepie:hover, .recepie:focus {
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.3); }
      .recepie:hover .recepie__title, .recepie:focus .recepie__title {
        border-bottom-color: #0E3C64; } }
  .recepie__image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 34rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 2rem;
    border-radius: 20px; }
    .recepie__image img {
      display: block;
      border-radius: 20px; }
  .recepie__title {
    display: inline-block;
    margin-bottom: 2px;
    font-size: 2rem;
    font-weight: 700;
    color: #0E3C64;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease-out; }
  .recepie__description {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3.6rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden; }
  .recepie__stats {
    display: flex; }
  .recepie__stats-item {
    display: flex;
    align-items: center;
    width: auto;
    min-width: 30%;
    color: transparent; }
    .recepie__stats-item:not(:first-child) {
      margin-left: 2rem; }
    .recepie__stats-item:nth-child(2n) {
      width: 8rem; }
    .recepie__stats-item svg {
      flex-shrink: 0;
      margin-right: 1rem; }
  .recepie__stats-name {
    font-size: 1.2rem;
    color: #171717; }
  @media (max-width: 1200px) {
    .recepie__image {
      margin-bottom: 1.7rem; }
    .recepie__title {
      font-size: 1.2rem; }
    .recepie__description {
      font-size: 1.2rem; }
    .recepie__stats-item:not(:first-child) {
      margin-left: 1rem; }
    .recepie__stats-item svg {
      width: 15px;
      height: 15px;
      margin-right: 5px; }
    .recepie__stats-name {
      font-size: 1rem; } }

@media (max-width: 1200px) and (max-width: 767px) {
  .recepie__description {
    height: 2.6rem; } }

.product-page {
  padding-top: 2.5rem; }
  .product-page__body {
    width: 85%;
    margin: 0 auto; }
  .product-page__title {
    margin-bottom: 4rem; }
  .product-page__content {
    display: flex;
    margin-bottom: 7.8rem; }
  .product-page__info {
    width: 42.75%;
    margin-right: auto; }
  .product-page__content-title {
    margin-bottom: 3.6rem;
    font-size: 2rem;
    font-weight: 700;
    color: #0E3C64; }
  .product-page__info-image {
    display: block;
    margin-left: -4rem;
    margin-bottom: 5rem; }
    .product-page__info-image img {
      display: block; }
  .product-page__stats {
    display: flex;
    color: transparent; }
  .product-page__stats-item {
    display: flex;
    align-items: center; }
    .product-page__stats-item:first-child {
      width: 21rem;
      margin-right: 2rem; }
    .product-page__stats-item:nth-child(2n) {
      width: 15rem; }
    .product-page__stats-item svg {
      flex-shrink: 0;
      margin-right: 2rem; }
  .product-page__stats-name {
    font-size: 1.5rem;
    color: #171717; }
  .product-page__description {
    width: 57.5%; }
  .product-page__ingridients {
    margin-bottom: 6rem;
    font-size: 1.5rem; }
  .product-page__ingridients-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem; }
    .product-page__ingridients-item:nth-child(odd) {
      background-color: #F3F3F3; }
  .product-page__ingridients-name {
    color: #171717; }
  .product-page__ingridients-value {
    color: #0E3C64; }
  .product-page__steps {
    counter-reset: myCounter; }
  .product-page__steps-item {
    font-size: 2rem;
    color: #171717; }
    .product-page__steps-item:not(:last-child) {
      margin-bottom: 1rem; }
    .product-page__steps-item::before {
      counter-increment: myCounter;
      content: counter(myCounter) ". "; }
  @media (max-width: 1200px) {
    .product-page {
      padding-top: 1.2rem; }
      .product-page__title {
        margin-bottom: 2rem; }
      .product-page__content {
        display: block;
        margin-bottom: 4rem; }
      .product-page__content-title {
        margin-bottom: 1.6rem;
        font-size: 1.2rem; }
      .product-page__info {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-bottom: 2.6rem; }
      .product-page__stats {
        margin-bottom: 2.6rem; }
      .product-page__stats-item:not(:first-child) {
        margin-left: 1rem; }
      .product-page__stats-item svg {
        width: 15px;
        height: 15px;
        margin-right: 5px; }
      .product-page__stats-name {
        font-size: 1rem; }
      .product-page__info-image {
        order: -1;
        max-width: 60%;
        margin-left: 0;
        margin-bottom: 0; }
      .product-page__description {
        width: 100%; }
      .product-page__ingridients {
        margin-bottom: 3rem; }
      .product-page__ingridients-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 0.8rem 1rem;
        font-size: 1.2rem; }
      .product-page__ingridients-name {
        margin-bottom: 2px; }
      .product-page__steps-item {
        font-size: 1.2rem; } }
  @media (max-width: 767px) {
    .product-page__body {
      width: 100%;
      padding: 0 2rem; } }

.recepies-slider {
  margin-bottom: 8rem; }
  .recepies-slider__body {
    width: 85%;
    max-width: 120rem;
    margin: 0 auto; }
  .recepies-slider__item {
    padding: 4rem 0 3rem; }
  .recepies-slider .recepie {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15); }
  .recepies-slider__button {
    display: none; }
  @media (max-width: 1200px) {
    .recepies-slider {
      margin-bottom: 5rem; }
      .recepies-slider .recepie__description {
        font-size: 1rem; } }
  @media (max-width: 767px) {
    .recepies-slider {
      margin-bottom: 3rem; }
      .recepies-slider__body {
        width: 100%;
        padding: 0 2rem; }
      .recepies-slider__title {
        margin-bottom: 2.5rem; }
      .recepies-slider__item {
        padding: 0;
        margin-bottom: 1.5rem; }
        .recepies-slider__item:nth-child(n + 4) {
          display: none; }
      .recepies-slider__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 3rem;
        padding: 7px 13px;
        margin: 3rem auto 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
        background-color: #0E3C64; } }

.good-page__body {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  max-width: 120rem;
  margin: 0 auto; }

.good-page__slider {
  position: relative;
  width: 50%;
  max-width: 58rem;
  height: 58rem; }

.good-page__image-nav {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  max-width: 11.3rem;
  height: 100%;
  margin: -1rem -2rem; }

.good-page__image-nav-item {
  width: 7.3rem;
  height: 7.3rem;
  margin: 1rem 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer; }

.good-page__image-nav-picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px; }

.good-page__image-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .good-page__image-main .slick-list {
    width: 100%;
    height: 100%; }
  .good-page__image-main .slick-track {
    height: 100%; }

.good-page__image-main-item.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center; }

.good-page__image-main-picture {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

.good-page__description {
  width: 50%;
  max-width: 50rem;
  margin-left: 3.6rem; }

.good-page__description-item:not(:last-child) {
  margin-bottom: 2rem; }

.good-page__description-title {
  margin-bottom: 1.1rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: #0E3C64; }

.good-page__description-value {
  font-size: 1.5rem;
  color: #171717; }

.good-page__stores {
  display: flex;
  flex-wrap: wrap; }

.good-page__stores-item {
  width: 6.2rem;
  height: 6.2rem;
  margin-bottom: 2rem;
  margin-right: 2.5rem; }
  .good-page__stores-item:nth-child(6n) {
    margin-right: 0; }

.good-page__stores-link {
  display: block; }
  @media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
    .good-page__stores-link:hover, .good-page__stores-link:focus {
      opacity: 0.6; } }

.good-page__content {
  margin: 6rem 0 6.8rem; }

.good-page__about-list {
  display: flex;
  flex-wrap: wrap;
  counter-reset: productCounter;
  margin-bottom: -2rem; }

.good-page__about-item {
  width: calc(50% - 3rem/2);
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #171717; }
  .good-page__about-item::before {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 5rem;
    height: 5rem;
    margin-right: 2.3rem;
    font-family: "Futura", "Arial", sans-serif;
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    border-radius: 50%;
    background-color: #003764;
    counter-increment: productCounter;
    content: counter(productCounter); }
  .good-page__about-item:nth-child(even) {
    margin-left: 3rem; }

.slider__item a {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

.recepies-slider__list .slick-dots {
  bottom: -1rem; }

@media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
  .page-footer__insta a:hover, .page-footer__insta a:focus {
    opacity: 0.6; } }

.title--mini {
  font-size: 3rem;
  font-weight: 700; }

.page-footer__insta {
  margin: 0 3rem; }

.page-footer__contacts {
  margin-left: auto;
  margin-right: 0; }

.page-header {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15); }

.table {
  border-radius: 4px; }
  .table__item {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    padding: 1.1rem 2rem;
    border-radius: inherit; }
    .table__item:nth-child(odd) {
      background-color: #f3f3f3; }
  .table__name {
    color: #171717; }
  .table__value {
    padding-left: 1rem;
    color: #0E3C64; }

.page-wrapper {
  width: 85%;
  max-width: 120rem;
  padding: 2.8rem 0 4.2rem;
  margin: 0 auto; }

@media (max-width: 1200px) {
  .page-wrapper {
    padding: 1.5rem 0 2rem; }
  .title--mini {
    font-size: 1.5rem; }
  .good-page__slider {
    width: 100%;
    max-width: 100%;
    height: 30rem; }
  .good-page__description {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem; }
  .good-page__description-item:not(:last-child) {
    margin-bottom: 1rem; }
  .good-page__description-title {
    font-size: 1.5rem; }
  .good-page__description-value,
  .table__item {
    font-size: 1.2rem; }
  .table__item {
    padding: 0.7rem 1rem; }
  .good-page__stores-item {
    width: calc(20% - 2rem*4/5);
    height: auto;
    margin-right: 2rem;
    margin-bottom: 2rem; }
  .good-page__stores-item:nth-child(6n) {
    margin-right: 2rem; }
  .good-page__stores-item:nth-child(5n) {
    margin-right: 0; }
  .good-page__content {
    margin: 3rem 0; }
  .good-page__about-item {
    width: 100%; }
  .good-page__about-item:nth-child(even) {
    margin-left: 0; }
  .good-page__about-item::before {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    font-size: 1.8rem; }
  .good-page__about-item {
    font-size: 1.2rem; }
  .recepies-slider__item {
    padding: 2rem 0; }
  .slick-slide .product__description {
    height: 5rem; }
  .page-footer__insta {
    margin: 0 0 1.5rem 0; } }

@media (max-width: 767px) {
  .good-page__body {
    width: 100%; }
  .good-page__image-nav {
    display: none; }
  .good-page__slider {
    height: 33rem; }
  .title--mini {
    font-size: 1.8rem; }
  .good-page__description {
    display: flex;
    flex-direction: column; }
  .good-page__description-item:nth-child(4) {
    order: -1; }
  .good-page__description-title {
    font-size: 1.2rem;
    margin-bottom: 1.8rem; }
  .good-page__description-item:not(:last-child) {
    margin-bottom: 2.5rem; }
  .table__item {
    font-size: 1.2rem;
    display: block; }
  .table__value {
    padding-left: 0;
    display: block; }
  .good-page__content {
    margin: 0;
    margin-bottom: 4rem; }
  .slider-recomendation {
    margin-bottom: 4.5rem; }
    .slider-recomendation .recepies-slider__item {
      padding: 0; }
  .slider-recomendation__title {
    max-width: 80%;
    font-size: 1.8rem; }
  .recepies-slider__button {
    width: 12rem; } }
